import {
  Box,
  Text,
  useToast,
  Flex,
} from "@chakra-ui/react";
import React, { useState } from "react";



export default function SelectRepairType({
  repairType,
  setRepairId,
  hasPrice,
  setHasPrice,
  repairId,
  setActiveStep,
  setCountStep,
  activeStep,
}) {
  const [activeIndex, setActiveIndex] = useState(null);
 
  const toast = useToast();

  const handleBoxClick = (index, item) => {
    setHasPrice(item?.price);
    setRepairId(item?.repair_type);
    setActiveIndex(index);
  };


  return (
    <Flex direction={"column"} justify={"center"} align={"center"}>


      <Text
        fontSize={"2.5rem"}
        fontWeight={"600"}
        className="brand-device-title"
      >
        Select Repair Type
      </Text>
      {repairType?.length > 0 ? (
        <Box className="repair-wrapper">
          {repairType &&
            repairType?.map((item, index) => {
              return (
                <Box key={index}
                  className="device-category"
                  border={`1px solid ${
                    activeIndex === index ? "black" : "#ddd"
                  }`}
                  onClick={() => handleBoxClick(index, item)} // Handle click and set the active index
                >
                  <img src={item?.icon} />
                  <h5
                    style={{
                      marginTop: "5px",
                      padding: "5px",
                      fontWeight: "600",
                      textAlign: "center",
                      textTransform: "capitalize",
                    }}
                  >
                    {item?.repair_type_name}
                  </h5>
                </Box>
              );
            })}
        </Box>
      ) : (
        <Box>
          <Text
            fontWeight={"bold"}
            color={"#439a97"}
            fontSize={"1rem"}
            mt="1rem"
          >
            Repair type is not availaible for this series/modle
          </Text>
        </Box>
      )}

      {/* PRICE SECTION */}
      {hasPrice && (
        <Box className="ticket-container">
          <Text textAlign={"center"} fontSize={{base:"1rem",md:"1.5rem",lg:"2rem"}} fontWeight={"bold"}>
            You have to pay{" "}
            <span style={{ color: " rgba(189, 124, 221, 0.933)" }}>Rs. {hasPrice}</span> for this
            repair
          </Text>
        </Box>
      )}

      <Flex gap={2}>
        {/* <button
          className="btn"
          onClick={() => {
            setActiveStep(activeStep - 1);
            setCountStep((prev) => prev - 1);
            window.location.reload();
          }}
        >
          Prev
        </button> */}
        <button
          className="btn"
          style={{
            backgroundColor: repairType.length === 0 ? "grey" : "black ",
            cursor: repairType.length === 0 ? "no-drop" : "pointer ",
          }}
          color={"#fff"}
          padding={"20px"}
          disabled={repairType.length === 0 ? true : false}
          margin={"5px"}
          onClick={() => {
            if (repairId === "") {
              toast({
                description: "Select the Repair Type.",
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "top-right",
              });
            } else {
              setActiveStep(activeStep + 1);
              setCountStep((prev) => prev + 1);
            }
          }}
        >
          Next
        </button>
      </Flex>


    </Flex>
  );
}
