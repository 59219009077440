import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";

const Footer = () => {
  return <>
    <Flex minHeight={"100px"} align={"center"} justify={"space-around"} backgroundColor={"blue.50"} marginTop={"2rem"} flexWrap={"wrap"}>

        <Box color={"grey"} fontSize={"12px"}>
            <Text>Copyright@We Fix It Store Nepal 2023</Text>
        </Box>

        <Box color={"grey"} fontSize={"12px"}>
            <Text textDecoration={"underline"}> <a href="https://aalayasofttech.com/" target="__blank">Created by Aalaya Soft Tech</a> </Text>
        </Box>

    </Flex>
  
  </>;
};

export default Footer;
