import React, { useEffect, useState } from "react";
import {
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  Box,
  Text,
  useToast,
  Button,
  Flex,
} from "@chakra-ui/react";
import apple from "../assets/apple.jpg";
import ValuationType from "./ValuationType";
import ValuationModal from "./ValuationModal";
import ValuationQuestion from "./ValuationQuestion";
import { baseUrl } from "../constants/baseUrl";
import axios from "axios";
import Contact from "./Contact";
import ValuationForm from "./ValuationForm";
export default function RepairLanding() {
  const [brand, setBrand] = useState("");
  const [activeIndex, setActiveIndex] = useState(null);
  const [nxtForm, setSelecteNxtForm] = useState(false);

  const steps = [
    { title: "Select Device Type", description: "Device Type" },
    { title: "Questions", description: "Questions" },
    { title: "Contact", description: "Contact" },
  ];

  const [countstep, setCountStep] = useState(0);
  const { activeStep, setActiveStep } = useSteps({
    index: countstep,
    count: steps.length,
  });

  // const { activeStep } = useSteps({
  //   index: 1,
  //   count: steps.length,
  // });

  const toast = useToast();
  const [question, setQuestion] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [nxtState, setSelecteNxtState] = useState(false);
  const [brandId, setBrandId] = useState("");
  const [deviceType, setDeviceType] = useState("");
  const [deviceId, setDeviceId] = useState("");
  const [deviceName, setDeviceName] = useState("");
  const [modal, setModal] = useState("");
  const [modalList, setModalList] = useState("");
  const [repairType, setRepairType] = useState("");
  const [hasPrice, setHasPrice] = useState(null);
  const [repairId, setRepairId] = useState("");
  const [answerList, setAnswerList] = useState([]);

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value); // Update the selected value in the state
  };
  const changeForm = () => {
    setSelecteNxtForm(true);
    setActiveStep(activeStep + 1);
  };
  const goBack = () => {
    setSelecteNxtForm(true);
    setActiveStep(activeStep - 1);
  };
  const changeState = () => {
    if (repairType?.length > 0) {
      setSelecteNxtState(true);
    } else {
      toast({
        title: "Error",
        description: "Repair type is not availaible for this series/modle.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  // API INTEGRATION
  useEffect(() => {
    axios
      .get(`${baseUrl}/api/brand`)
      .then((response) => {
        setBrandId(response?.data[0]?.uuid);
        setBrand(response.data);
      })
      .catch((error) => {
        // Handle any errors that occur during the
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleBoxClick = (index, item) => {
    setBrandId(item?.uuid);
    setActiveIndex(index);
  };

  // FETCHING REPAIR TYPE
  useEffect(() => {
    if (deviceId && brandId && selectedValue) {
      axios
        .get(
          `${baseUrl}/api/brand-series/repair-type/?type=${
            deviceId ?? ""
          }&brand=${brandId}&series=${selectedValue ?? ""}`
        )
        .then((response) => {
          setRepairType(response?.data);
        })
        .catch((error) => {
          // Handle any errors that occur during the
          console.error("Error fetching data:", error);
        });
    }
  }, [deviceId, brandId, selectedValue]);

  
  const handleStepIndicatorClick = (index) => {
    if (activeStep === 1 && index === 0) {
      window.location.reload();
      setActiveStep(activeStep - 1);
      setCountStep((prev) => prev - 1);
    } else if (activeStep === 2 && index === 1) {
      
      setActiveStep(activeStep - 1);
      setCountStep((prev) => prev - 1);
    }
  
  };

  return (
    <div>
      {!nxtState ? (
        <Box>
          <Stepper index={activeStep}>
            {steps.map((step, index) => (
              <Step key={index}>
                <StepIndicator
                  width="20px"
                  height="20px"
                  padding="12px"
                  style={{
                    cursor:
                      activeStep === 1 && index === 0
                        ? "pointer"
                        : activeStep === 2 && index === 1
                        ? "pointer"
                        : "no-drop",
                  }}
                  onClick={() => handleStepIndicatorClick(index)}
                >
                  <StepStatus
                    fontSize="4px"
                    complete={<StepIcon />}
                    incomplete={<StepNumber />}
                    active={<StepNumber />}
                  />
                </StepIndicator>

                <Box flexShrink="0" className="stepperbox">
                  <StepTitle fontSize="14px" fontWeight="600">
                    {step.title}
                  </StepTitle>
                  <StepDescription fontSize="12px">
                    {step.description}
                  </StepDescription>
                </Box>

                <StepSeparator />
              </Step>
            ))}
          </Stepper>

          <Box className="brand-container" mt="1rem ">
            {activeStep === 0 ? (
              <>
                <Flex
                  direction={{
                    base: "column",
                    md: "row",
                    lg: "row",
                    xl: "row",
                  }}
                  align={"center"}
                  justify={"center"}
                  gap={2}
                >
                  <Box textAlign={"center"}>
                    <Text className="brand-container-title">
                      Here is your desired brand Apple
                    </Text>
                    <span className="brand-container-subtitle">
                      This brand is selected by default
                    </span>
                  </Box>

                  {brand &&
                    brand.map((item, index) => (
                      <Box key={index}
                        cursor="pointer"
                        border={`1px solid ${
                          activeIndex === index ? "black" : "white"
                        }`}
                        width="100px"
                        padding="5px"
                        borderradius={"5px"}
                     
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        onClick={() => handleBoxClick(index, item)} // Handle click and set the active index
                      >
                        <img
                          borderradius="25%"
                          src={item?.icon}
                          alt="Your Image"
                          style={{ width: "100%", height: "30%" }}
                        />
                      </Box>
                    ))}
                </Flex>

                <ValuationType
                  brandId={brandId}
                  deviceType={deviceType}
                  setDeviceType={setDeviceType}
                  deviceId={deviceId}
                  setDeviceId={setDeviceId}
                />

                {deviceId && (
                  <ValuationModal
                    selectedValue={selectedValue}
                    setSelectedValue={setSelectedValue}
                    handleSelectChange={handleSelectChange}
                    nxtState={nxtState}
                    setSelecteNxtState={setSelecteNxtState}
                    changeState={changeState}
                    deviceId={deviceId}
                    modalList={modalList}
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    setModalList={setModalList}
                    brandId={brandId}
                    setCountStep={setCountStep}
                  />
                )}
              </>
            ) : activeStep === 1 ? (
              <>
                <ValuationQuestion
                  nxtForm={nxtForm}
                  changeForm={changeForm}
                  brandId={brandId}
                  deviceId={deviceId}
                  selectedValue={selectedValue}
                  modal={modal}
                  setModal={setModal}
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  
                  repairType={repairType}
                  setRepairType={setRepairType}
                  setSelecteNxtState={setSelecteNxtState}
                  setCountStep={setCountStep}
                  goBack={goBack}
                  question={question}
                  setQuestion={setQuestion}
                  answerList={answerList}
                  setAnswerList={setAnswerList}
                />
              </>
            ) : activeStep === 2 ? (
              <>
                <ValuationForm
                  selectedValue={selectedValue}
                  brandId={brandId}
                  deviceId={deviceId}
                  answerList={answerList}
                  setAnswerList={setAnswerList}
                  goBack={goBack}
                />
              </>
            ) : null}
          </Box>
        </Box>
      ) : (
        <Box></Box>
      )}
    </div>
  );
}
