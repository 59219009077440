import logo from "./logo.svg";
import "./App.css";
import Repair from "./pages/Repair";
import { Box, ChakraProvider, theme } from "@chakra-ui/react";
import { extendTheme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools";

function App() {
  const breakpoints = createBreakpoints({
    sm: "30em" /* 480px */,
    md: "60em" /* 768px */,
    lg: "80em" /* 992px */,
    xl: "80em" /*1280px 1517 */,
    "2xl": "96em" /* 1536px */,
  });
  const theme = extendTheme({ breakpoints });

  return (
    <Box>
     
      <Repair />
    </Box>
  );
}

export default App;
