import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import App from "./App";
import { Box, ChakraProvider } from "@chakra-ui/react";
import { extendTheme } from "@chakra-ui/react";

import { createBreakpoints } from "@chakra-ui/theme-tools";
import Valuation from "./pages/Valuation";
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";

const breakpoints = createBreakpoints({
  sm: "30em" /* 480px */,
  md: "60em" /* 768px */,
  lg: "80em" /* 992px */,
  xl: "80em" /*1280px 1517 */,
  "2xl": "96em" /* 1536px */,
});
const theme = extendTheme({ breakpoints });
const router = createBrowserRouter([
  {
    path: "/repair",
    element: <App />,
  },
  {
    path: "/valuation",
    element: <Valuation />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <Navigation />
      <Box paddingLeft={"2rem"} minHeight={"85vh"} paddingTop={"1rem"} paddingRight={"2rem"} className="app">
        <RouterProvider router={router} />
      </Box>
      <Footer/>
    </ChakraProvider>
  </React.StrictMode>
);
