import React from "react";
import ValuationLanding from "../views/ValuationLanding";

export default function Valuation() {
  return (
    <div>
      <ValuationLanding />
    </div>
  );
}
