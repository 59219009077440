import {
  Box,
  Button,
  Input,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  useToast,
  Flex,
} from "@chakra-ui/react";
import React, { useState } from "react";

import { useForm, Controller } from "react-hook-form";
import { baseUrl } from "../constants/baseUrl";
import axios from "axios";
import { FaPhoneAlt } from "react-icons/fa";

export default function Contact({
  brandId,
  deviceId,
  selectedValue,
  hasPrice,
  repairId,
  setActiveStep,
  setCountStep,
  activeStep,
}) {
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [ticketId, setTicketId] = useState("");
  const toast = useToast();

  const { handleSubmit, control, reset } = useForm();

  // API INTEGRATION

  const handleCloseSuccessModal = () => {
    // Close the success modal
    window.location.reload();
    setIsSuccessModalOpen(false);
  };

  const onSubmit = async (data) => {
    const value = {
      name: data.fullName,
      email: data.email,
      phone: data.phoneNumber,
      brand: brandId,
      device_type: deviceId,
      series: selectedValue,
      repair_type: repairId,
      price: hasPrice,
    };
    try {
      const response = await axios.post(`${baseUrl}/api/user-request/`, value);
      setIsSuccessModalOpen(true);
      setTicketId(response?.data?.data?.ticket);
      reset();
     
    } catch (error) {
      toast({
        description: "Please select one of the repair types.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
     
    }
  };

  return (
    <Flex align={"center"} justifyContent={"center"}>
      <Modal isOpen={isSuccessModalOpen} onClose={handleCloseSuccessModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader  textAlign={"center"}> Thank you for enquiry! </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text
              mt="0.5rem"
              textAlign={"center"}
              fontSize={"1.25rem"}
              fontWeight={"bold"}
            >
          We have received your request. Our support team will contact you soon. 
            </Text>
            <Text
              mt="0.5rem"
              fontWeight={"bold"}
              fontSize={"1.2rem"}
              textAlign={"center"}
              
            >
              Or
            </Text>
            <Text
              mt="0.5rem"
              fontWeight={"bold"}
              color={"grey"}
              textAlign={"center"}
            >
             Contact us at  <br/>
             <Flex gap={2} align={"center"} justify={"center"}>
             <FaPhoneAlt /> <a href="tel:986008899" style={{textDecoration:"underline"}}>+977-9869696969</a>
             </Flex>
            
            </Text>
          </ModalBody>
          <ModalFooter>
            <button className="btn"  onClick={handleCloseSuccessModal}>
              Close
            </button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* <Flex gap={2}>
        <button
          className="btn"
          onClick={() => {
            setActiveStep(activeStep - 1);
            setCountStep((prev) => prev - 1);
          }}
        >
          Prev
        </button>
      </Flex> */}

      {/* FORM SECTION */}
      <Flex direction={"column"} width={{ md: "80%", lg: "60%" }}>
        <Box className="repair-form">
          <h5 style={{ fontWeight: "bold", color: "black" }}>
            Note: For certain models, where the screen has to be removed using a
            hot gun either to replace the LCD itself or any other components, it
            will take 4 hours - 5 hours. We will inform you ahead of time
            whether this would be the case with your phone.
          </h5>
          <h4 style={{ color: "black" }}>
            Please Enter Your Name, Email & Phone Number To Receive Quotation
          </h4>
        </Box>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            display={"flex"}
            gap={"1.5rem"}
            flexDirection={"column"}
            mt="1rem"
          >
            <Controller
              name="fullName"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input
                  {...field}
                  style={{border:"1px solid rgba(189, 124, 221, 0.933)"}}
                  width={{ md: "80%", lg: "60%", xl: "70%" }}
                  type="text"
                  placeholder="Full Name"
                  size="md"
                  required
                />
              )}
            />
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input
                  {...field}
                  style={{border:"1px solid rgba(189, 124, 221, 0.933)"}}
                  width={{ md: "80%", lg: "60%", xl: "70%" }}
                  type="email"
                  placeholder="Email"
                  size="md"
                  required
                />
              )}
            />
            <Controller
              name="phoneNumber"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input
                style={{border:"1px solid rgba(189, 124, 221, 0.933)"}}
                  {...field}
                  width={{ md: "80%", lg: "60%", xl: "70%" }}
                  type="number"
                  placeholder="Phone Number"
                  size="md"
                  required
                />
              )}
            />
          </Box>
          <button className="btn" type="submit">
            Submit
          </button>
        </form>
      </Flex>
    </Flex>
  );
}
