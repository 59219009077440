import {
  Avatar,
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Link,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import Logo from "../assets/logo.png";

import { DragHandleIcon } from "@chakra-ui/icons";

const textStyle = {
  color: "grey",
  fontSize: "12px",
};

const Navigation = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const btnRef = React.useRef();

  return (
    <>
      <Flex
        align={"center"}
        minHeight={"74px"}
        padding={"5px"}
        justify={{ md: "flex-start", lg: "center" }}
        gap={4}
      >
        {/* Desktop Navbar  */}
        <Flex
          align={"center"}
          justify={{ md: "flex-start", lg: "center" }}
          gap={5}
          width={"100%"}
          hideBelow="md"
        >
          <img
            style={{ height: "60px", paddingRight: "6rem" }}
            src={Logo}
            alt="logo"
          />

          <ul
            style={{
              display: "flex",
              gap: "1.5rem",
              textDecoration: "none",
              listStyle: "none",
              fontWeight: "600",
            }}
          >
            <li className="navbutton">
              <a href="https://wefixitstorenepal.com/">Home</a>
            </li>
            <li className="navbutton">
              <a href="https://wefixitstorenepal.com/#/about">About</a>
            </li>
            <li className="navbutton">
              <a href="https://wefixitstorenepal.com/#/portfolio">Portfoilo</a>{" "}
            </li>
            <li className="navbutton">
              <a href="https://wefixitstorenepal.com/#/">Drop Off</a>{" "}
            </li>

            <Link href="/valuation" className="navbutton">
              Sell Phone
            </Link>

            <Link href="/repair" className="navbutton">
              Repair Phone
            </Link>

            <li className="navbutton">
              <a
                href="https://wefixitstorenepal.com/#contact"
                target="_blank"
                rel="noopener noreferrer"
              >
                Contact
              </a>{" "}
            </li>
            <li className="navbutton">
              <a href="https://wefixitstorenepal.com/#/blog">Blog</a>{" "}
            </li>
          </ul>

          <Flex align={"center"} justifyContent={"flex-start"} gap={3}>
            {/* <Box className="input-container">
              <input
                type="text"
                style={{
                  fontSize: "14px",
                  fontWeight: "lighter",
                  padding: "0.5rem",
                  border: "none",
                  outline: "none",
                }}
                placeholder="Ticket Id or Phone"
                padding="1rem"
              />

              <CiSearch />
            </Box> */}

            <button className="btn1">Contact Us</button>
          </Flex>
        </Flex>

        {/* Mobile HamburgerIcon  */}
        <Box flex="0.5" hideFrom="md" pl={3}>
          <button
            className="bgcolor"
            ref={btnRef}
            onClick={onOpen}
            hidefrom="md"
            cursor={"pointer"}
          >
            <DragHandleIcon color={"white"} />
          </button>
        </Box>
      </Flex>

      {/* Mobile Navbar  */}
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        finalFocusRef={btnRef}
        hideFrom="md"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <Flex gap="5" direction={"column"}>
              <Avatar
                src={Logo}
                width="80px"
                height="80px"
                objectFit={"contain"}
              />

              <h1>We Fix It Store Nepal</h1>
            </Flex>
          </DrawerHeader>

          <DrawerBody>
            <Flex direction={"column"} gap="2" pt={2} textAlign={"start"}>
              <ul
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.2rem",
                  textDecoration: "none",
                  listStyle: "none",
                  fontWeight: "500",
                }}
              >
                <li className="navbutton">
                  <a href="https://wefixitstorenepal.com/">Home</a>
                </li>
                <li className="navbutton">
                  <a href="https://wefixitstorenepal.com/#about">About</a>
                </li>
                <li className="navbutton">
                  <a href="">Portfoilo</a>{" "}
                </li>
                <li className="navbutton">
                  <a href="">Drop Off</a>{" "}
                </li>

                <Link href="/valuation" className="navbutton">
                  Sell Phone
                </Link>

                <Link href="/repair" className="navbutton">
                  Repair Phone
                </Link>
                <li className="navbutton">
                  <a href="">Contact</a>{" "}
                </li>
                <li className="navbutton">
                  <a href="https://wefixitstorenepal.com/#/blog">Blog</a>{" "}
                </li>
              </ul>

              {/* <Box className="input-container">
                <input
                  type="text"
                  style={{
                    fontSize: "14px",
                    fontWeight: "lighter",
                    padding: "0.5rem",
                    border: "none",
                    outline: "none",
                  }}
                  placeholder="Ticket Id or Phone"
                  padding="1rem"
                />

                <CiSearch />
              </Box> */}
            </Flex>
          </DrawerBody>

          <DrawerFooter>
            <Text style={textStyle}>Copyright@We Fix It Store Nepal 2023</Text>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default Navigation;
