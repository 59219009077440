import { Box, Flex, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import phone from "../assets/phone.jpg";
import axios from "axios";
import { baseUrl } from "../constants/baseUrl";

export default function ValuationType({
  brandId,
  deviceType,
  setDeviceType,
  deviceId,
  setDeviceId,
}) {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleBoxClick = (index, item) => {
    setDeviceId(item?.uuid);
    setActiveIndex(index);
  };


  // API INTEGRATION
  useEffect(() => {
    if (brandId) {
      axios
        .get(`${baseUrl}/api/brand-type/?uuid=${brandId ?? ""}`)
        .then((response) => {
          setDeviceType(response?.data);
          
        })
        .catch((error) => {
          // Handle any errors that occur during the
          console.error("Error fetching data:", error);
        });
    }
  }, [brandId]);
 
  return (
    <div>
      <Flex
        direction={"column"}
        align={"center"}
        justify={"center"}
        mt={"0.9rem"}
        p={5}
        borderradius={"10px"}
      >
        <Text
          fontSize={"2.5rem"}
          fontWeight={"600"}
          className="brand-device-title"
        >
          Select Device Type
        </Text>
        <Flex
          flexWrap={"wrap"}
          justify={"center"}
          gap={9}
          className="type-wrapper"
        >
          {deviceType &&
            deviceType?.map((item, index) => {
              return (
                <Box key={index}
                  className="device-category"
                  padding="5px"
                  // border={`2px solid ${
                  //   activeIndex === index ? "blue" : "#ddd"
                  // }`}
                  boxShadow={
                    activeIndex === index
                      ? "0 0 10px rgba(0, 0, 0, 0.12)"
                      : "none"
                  }
                  onClick={() => handleBoxClick(index, item)} // Handle click and set the active index
                >
                  <img src={item?.icon} />
                  <h5
                    style={{
                      marginTop: "5px",
                      fontWeight: "600",
                      textAlign: "center",
                      textTransform: "capitalize",
                    }}
                  >
                    {item?.title}
                  </h5>
                </Box>
              );
            })}
        </Flex>
      </Flex>
    </div>
  );
}
