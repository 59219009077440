import { Select, Box, Button, Flex, Heading, useToast } from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect } from "react";
import { baseUrl } from "../constants/baseUrl";

export default function RepairModle({
  selectedValue,
  handleSelectChange,
  changeState,
  deviceId,
  modalList,
  setModalList,
  brandId,
  setActiveStep,
  setCountStep,
  activeStep,
  deviceName,
}) {
  // API INTEGRATION
  useEffect(() => {
    if (deviceId && brandId) {
      axios
        .get(
          `${baseUrl}/api/brand-type/series/?type_uuid=${
            deviceId ?? ""
          }&brand_uuid=${brandId}`
        )
        .then((response) => {
          setModalList(response?.data);
        })
        .catch((error) => {
          // Handle any errors that occur during the
          console.error("Error fetching data:", error);
        });
    }
  }, [deviceId, brandId]);

  const toast = useToast();

  return (
    <>
      <Flex direction={"column"} align={"center"} justify={"center"} mt="2rem">
        <Heading
          textAlign={"center"}
          textTransform={"capitalize"}
          padding={2}
          fontSize={"1.2rem"}
        >
          {deviceName}
        </Heading>

        <Select
          width={"50%"}
          placeholder="Select model"
          onChange={handleSelectChange} // Add the onChange event handler
          value={selectedValue}
        >
          {modalList &&
            modalList?.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <option  style={{textTransform:"capitalize"}} value={item?.uuid}>{item?.title}</option>
                </React.Fragment>
              );
            })}
        </Select>

        <Flex gap={2}>
          <button
            className="btn "
            onClick={() => {
              if (selectedValue === "") {
                toast({
                
                  description: "Select the Device Modal.",
                  status: "error",
                  duration: 5000,
                  isClosable: true,
                  position: "top-right",
                });
              } else {
                setActiveStep(activeStep + 1);
                setCountStep((prev) => prev + 1);
              }
            }}
          >
            Next
          </button>
        </Flex>
      </Flex>
    </>
  );
}
