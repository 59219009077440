import { Box, Flex, Input, useToast } from "@chakra-ui/react";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { baseUrl } from "../constants/baseUrl";
import axios from "axios";

export default function ValuationForm({
  goBack,
  answerList,
  brandId,
  deviceId,
  selectedValue,
}) {
  const toast = useToast();

  const { handleSubmit, control, reset } = useForm();
  const onSubmit = async (data) => {
    const answer = answerList;
   

    const value = {
      name: data.fullName,
      email: data.email,
      phone: data.phoneNumber,
      brand: brandId,
      device_type: deviceId,
      series: selectedValue,
      valuation: answer,
    };

  

    try {
      const response = await axios.post(
        `${baseUrl}/api/valuation-request/`,
        value
      );

      if (response?.status === 201) {
        toast({
          description: "Success!",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (error) {
      toast({
        description: error?.response?.data?.error,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  };
  return (
    <Flex align={"center"} justifyContent={"center"}>
      <Flex direction={"column"} width={{ md: "80%", lg: "60%" }}>
        <h2 className="form-heading">
          Please Enter Your Name, Email & Phone Number
        </h2>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            display={"flex"}
            gap={"1.5rem"}
            flexDirection={"column"}
            mt="1rem"
          >
            <Controller
              name="fullName"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input
                  {...field}
                  width={{ md: "80%", lg: "60%", xl: "70%" }}
                  style={{ border: "1px solid rgba(189, 124, 221, 0.933)" }}
                  type="text"
                  placeholder="Full Name"
                  size="md"
                  required
                />
              )}
            />
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input
                  {...field}
                  width={{ md: "80%", lg: "60%", xl: "70%" }}
                  style={{ border: "1px solid rgba(189, 124, 221, 0.933)" }}
                  type="email"
                  placeholder="Email"
                  size="md"
                  required
                />
              )}
            />
            <Controller
              name="phoneNumber"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input
                  {...field}
                  width={{ md: "80%", lg: "60%", xl: "70%" }}
                  style={{ border: "1px solid rgba(189, 124, 221, 0.933)" }}
                  type="number"
                  placeholder="Phone Number"
                  size="md"
                  required
                />
              )}
            />
          </Box>
          <button className="btn" type="submit">
            Submit
          </button>
        </form>
      </Flex>
    </Flex>
  );
}
