import React from "react";
import RepairLanding from "../views/RepairLanding";

export default function Repair() {
  return (
    <div>
      <RepairLanding />
    </div>
  );
}
