import React, { useEffect, useState } from "react";
import { Box, Text, Button, useToast } from "@chakra-ui/react";
import { useForm, Controller } from "react-hook-form";
import ValuationForm from "./ValuationForm";
import axios from "axios";
import { baseUrl } from "../constants/baseUrl";

// const questions = [
//   "Has your phone ever had liquid damage? ",
//   "Does your device switch on ?",
//   "Have you ever repaired your Board?",
//   "Is your wifi and bluetooth working?",
// ];

export default function ValuationQuestion({
  changeForm,
  question,
  setQuestion,
  setActiveStep,
  activeStep,
  setCountStep,
  setAnswerList,
  answerList,
}) {
  const { control, handleSubmit, setValue, getValues } = useForm();
  // API INTEGRATION
  useEffect(() => {
    axios
      .get(`${baseUrl}/api/valuation/`)
      .then((response) => {
        setQuestion(response?.data);
      })
      .catch((error) => {
        // Handle any errors that occur during the
        console.error("Error fetching data:", error);
      });
  }, []);

  const toast = useToast();

  const onSubmit = (data) => {
    const formData = question.map((item, index) => ({
      question: item?.uuid,
      answer: getValues(`question_${index}`) ?? "null",
    }));

    setAnswerList(formData);
  };

 

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          {question &&
            question?.map((item, index) => (
              <div key={index}>
                <Text className="form-question">{item?.question}</Text>
                <Controller
                  control={control}
                  name={`question_${index}`}
                  render={({ field }) => (
                    <Box className="valuation-btn">
                      <Button
                        colorScheme={
                          getValues(`question_${index}`) === "yes"
                            ? "purple"
                            : "gray"
                        }
                        // onClick={() => setAnswerList(item, "yes")}
                        onClick={() => setValue(`question_${index}`, "yes")}
                        className="valuation-btn_button"
                        {...field}
                        value="yes"
                        type="submit"
                        required
                      >
                        Yes
                      </Button>
                      <Button
                        colorScheme={
                          getValues(`question_${index}`) === "no"
                            ? "purple"
                            : "gray"
                        }
                        onClick={() => setValue(`question_${index}`, "no")}
                        // onClick={() => setAnswerList(item, "no")}
                        className="valuation-btn_button"
                        {...field}
                        value="no"
                        type="submit"
                        required
                      >
                        No
                      </Button>
                    </Box>
                  )}
                />
              </div>
            ))}

          <Box className="btn-wrapper">
      

            <button
            className="btn"
              // onClick={() => changeForm()}
              onClick={() => {
                if (answerList.length === 0 || answerList.some((elem) => elem.answer === "null")) {
                  toast({
                    description: "Select all the questions.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                    position: "top-right",
                  });
                } else {
                  setActiveStep(activeStep + 1);
                  setCountStep((prev) => prev + 1);
                }
              }}
            >
              Next
            </button>
          </Box>
        </Box>
      </form>
    </>
  );
}
